<template>
	<div>
		<app-layout>
			<template v-slot:header>
				<Header :title="$t('yoksis_import_title')"
				        :isFilter="false"/>
			</template>
			<template v-slot:header-mobile>
				<HeaderMobile :title="$t('yoksis_import_title')"
				              :isFilter="false"/>
			</template>
			<div class="row mb-5 align-items-center">
				<div class="col-12 col-md-12 col-xl-12 col-xxl-12 mt-4">
					<ValidationObserver ref="importForm">
						<div class="row">
							<div class="col-6 col-md-6 col-xl-6 col-xxl-6">
								<ValidationProvider name="academicYear" rules="required" v-slot="{valid, errors}">
									<b-form-group :label="$t('academic_year')">
										<academic-years-selectbox :validate-error="errors[0]"
										                          v-model="importData.academicYear">
										</academic-years-selectbox>
									</b-form-group>
								</ValidationProvider>
							</div>
							<div class="col-6 col-md-6 col-xl-6 col-xxl-6">
								<ValidationProvider name="registrationType" rules="required" v-slot="{valid, errors}">
									<b-form-group :label="$t('registration_types')">
										<parameter-selectbox
											:validate-error="errors[0]"
											code="yoksis_import_registration_types"
											v-model="importData.registrationType"
										></parameter-selectbox>
									</b-form-group>
								</ValidationProvider>
							</div>
						</div>
					</ValidationObserver>
				</div>
				<div class="col-md-6 col-sm-12">
					<div class="row">
						<div class="col-sm-6 col-xs-12 mb-5">
							<b-button @click="submitImportForm" v-if="this.checkPermission('registration_yoksisimport')"
							          type="button"
							          variant="primary"
							          class="btn-lg btn-block d-flex justify-content-between align-items-center"
							>
								{{ $t('import_button').toUpper() }}
							</b-button>
						</div>
						<div class="col-sm-6 col-xs-12 mb-5">
							<b-button @click="submitPhotoImportForm"
							          type="button"
							          variant="primary"
							          class="btn-lg btn-block d-flex justify-content-between align-items-center"
							>
								{{ $t('import_photos').toUpper() }}
							</b-button>
						</div>
					</div>
				</div>
				<div class="col-md-6 col-sm-12">
					<div class="row">
						<div class="col-sm-6 col-xs-12 mb-5">
							<b-button @click="submitExportForm"
							          type="button"
							          variant="primary"
							          class="btn-lg btn-block d-flex justify-content-between align-items-center"
							>
								{{ $t('download_data').toUpper() }}
							</b-button>
						</div>
						<div class="col-sm-6 col-xs-12 mb-5">
							<b-button @click="submitPhotoExportForm"
							          type="button"
							          variant="primary"
							          class="btn-lg btn-block d-flex justify-content-between align-items-center"
							>
								{{ $t('download_photos').toUpper() }}
							</b-button>
						</div>
					</div>
				</div>
			</div>
		</app-layout>
	</div>
</template>
<script>
	// Template
	import AppLayout from "@/layouts/AppLayout";
	import Header from "@/layouts/AppLayout/Header";
	import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

	// Component
	import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
	import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";

	// Services
	import RegistrationService from "@/services/RegistrationService";

	// Other
	import {ValidationObserver, ValidationProvider} from "vee-validate";

	export default {
		components: {
			AppLayout,
			Header,
			HeaderMobile,
			AcademicYearsSelectbox,
			ParameterSelectbox,
			ValidationProvider,
			ValidationObserver
		},
		data() {
			return {
				importData: {
					academicYear: null,
					registrationType: null,
				}
			}
		},
		metaInfo() {
			return {
				title: this.$t("yoksis_import_title")
			}
		},
		methods: {
			async submitImportForm() {
				const isValid = await this.$refs.importForm.validate();
				if (isValid) {
					RegistrationService.yoksisImport(this.importData.academicYear, this.importData.registrationType)
					                   .then(response => {
						                   this.$toast.success(this.$t(response.data.message));
						                   this.$router.push('/job/logs/detail/' + response.data.data.log_id)
					                   })
					                   .catch(e => {
						                   if (e.status == '404') {
							                   if (e.data.message) {
								                   this.$refs.importForm.errors.academicYear.push(e.data.message);
							                   }
						                   }
						                   else if (e.status == '500') {
							                   if (e.data.message) {
								                   this.$toast.error(this.$t('api.' + e.data.message));
							                   }
						                   }
						                   else if (e.status == '406') {
							                   if (e.data.message) {
								                   this.$refs.importForm.errors.academicYear.push(this.$t('api.' + e.data.message));
							                   }
						                   }
					                   })
				}
			},
			async submitPhotoImportForm() {
				const isValid = await this.$refs.importForm.validate();
				if (isValid) {
					RegistrationService.yoksisPhotoImport(this.importData.academicYear, this.importData.registrationType)
					                   .then(response => {
						                   this.$toast.success(this.$t(response.data.message));
					                   })
					                   .catch(e => {
						                   this.$toast.error(this.$t('api.' + e.data.message));
					                   });
				}
			},
			async submitExportForm() {
				const isValid = await this.$refs.importForm.validate();
				if (isValid) {
					RegistrationService.yoksisExport(this.importData.academicYear, this.importData.registrationType)
					                   .then(response => {
						                   const blob = new Blob([response.data], {type: response.headers['content-type']})
						                   const data = window.URL.createObjectURL(blob);
						                   var link = document.createElement('a');
						                   link.href = data;
						                   link.download = this.importData.academicYear + "-" + this.importData.registrationType + ".xlsx";
						                   link.click();
						                   setTimeout(function () {
							                   window.URL.revokeObjectURL(data);
						                   }, 100);
					                   })
					                   .catch(e => {
						                   var decoder = new TextDecoder("utf-8");
						                   var data = JSON.parse(decoder.decode(new Uint8Array(e.data)));
						                   this.$toast.error(this.$t('api.' + data.message));
					                   });
				}
			},
			async submitPhotoExportForm() {
				const isValid = await this.$refs.importForm.validate();
				if (isValid) {
					RegistrationService.yoksisPhotoExport(this.importData.academicYear, this.importData.registrationType)
					                   .then(response => {
						                   const blob = new Blob([response.data], {type: response.headers['content-type']})
						                   const data = window.URL.createObjectURL(blob);
						                   var link = document.createElement('a');
						                   link.href = data;
						                   link.download = this.importData.academicYear + "-" + this.importData.registrationType + ".zip";
						                   link.click();
						                   setTimeout(function () {
							                   window.URL.revokeObjectURL(data);
						                   }, 100);
					                   })
					                   .catch(e => {
						                   var decoder = new TextDecoder("utf-8");
						                   var data = JSON.parse(decoder.decode(new Uint8Array(e.data)));
						                   this.$toast.error(this.$t('api.' + data.message));
					                   });
				}
			}
		}
	}
</script>
